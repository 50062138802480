<template>
    <VVVLayoutNew>
        <template #page-title>
            New Claim
        </template>
        <div class="backdrop" v-if="isLoading">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/VVV/vvv-loader.png" alt="Loading image" />
            </div>
        </div>
        <template v-else>
            <MDBCard class="shadow-0" style="flex: 1 1 auto; overflow: hidden;">
                <MDBCardHeader class="d-flex align-items-center justify-content-between px-0">
                    <div class="stepper-container d-flex justify-content-start align-items-center">
                        <ul class="stepper" data-mdb-stepper-init style="font-size: 13px;">
                            <li :class="stepper1Class" @click="stepCounter = 1" style="max-height: 7vh;">
                                <div class="stepper-head" style="height: 7vh;">
                                    <span class="stepper-head-icon">1</span>
                                    <span class="stepper-head-text fw-bold">Step 1</span>
                                </div>
                            </li>
                            <li :class="stepper2Class" @click="validateStepOne()" style="max-height: 7vh;">
                                <div class="stepper-head" style="height: 7vh;">
                                    <span class="stepper-head-icon">2</span>
                                    <span class="stepper-head-text fw-bold">Step 2</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <router-link to="/bulk-claims" custom v-slot="{ href, navigate }">
                        <MDBBtn class="py-2" color="primary" size="sm" tag="a" :href="href" @click.stop="navigate" style="font-weight: bold;">
                            <vue-feather type="file-plus" :stroke-width="2.5" style="height: 1.1rem;" />
                            Try Bulk Claims!
                        </MDBBtn>
                    </router-link>
                </MDBCardHeader>
                <MDBCardBody class="px-0 overflow-auto pe-3">
                    <template v-if="stepCounter === 1">
                        <NewClaimYouTubeUrlInputNew />
                    </template>
                    <template v-else>
                        <NewClaimInputClipsNew @deleteClip="removeClip" @copyClip="duplicateClip" />
                    </template>
                </MDBCardBody>
                <MDBCardFooter
                    class="d-flex flex-md-row flex-column justify-content-end align-items-center py-3 gap-3 w-100">
                    <template v-if="stepCounter === 1">
                        <MDBBtn class="fw-bold" color="primary" size="sm" @click="validateStepOne()">
                            Next Step
                            <vue-feather type="arrow-right" :stroke-width="2.5" style="height: 1.2rem;" />
                        </MDBBtn>
                    </template>
                    <template v-else>
                        <MDBBtn class="fw-bold" size="sm" @click="stepCounter--" style="display: flex;align-items: center;">
                            <vue-feather type="arrow-left" :stroke-width="2.5" style="height: 1.2rem;" />
                            Previous Step
                        </MDBBtn>
                        <MDBBtn class="fw-bold" color="primary" size="sm" @click="addNewClip(null, null)">
                            Add More
                            <vue-feather type="plus" :stroke-width="2.5" style="height: 1.2rem;" />
                        </MDBBtn>
                        <MDBBtn class="fw-bold" color="primary" size="sm" @click="submitNewClaim()">
                            <template v-if="loading">
                                Processing
                                <vue-feather type="loader" animation="spin" animation-speed="slow" style="height: 1.2rem;" />
                            </template>
                            <template v-else>
                                Confirm
                                <vue-feather type="check" :stroke-width="2.5" style="height: 1.2rem;" />
                            </template>
                        </MDBBtn>
                    </template>
                </MDBCardFooter>
            </MDBCard>
        </template>
        <MDBToast v-model="errorToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="danger" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Error </template>
            {{ errorToastMessage }}
        </MDBToast>
    </VVVLayoutNew>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import VVVLayoutNew from "@/views/VVVNew/VVVLayoutNew.vue";
import NewClaimYouTubeUrlInputNew from "@/views/VVVNew/NewClaimNew/NewClaimYouTubeUrlInputNew";
import NewClaimInputClipsNew from "@/views/VVVNew/NewClaimNew/NewClaimInputClipsNew";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBBtn, MDBToast } from "mdb-vue-ui-kit";
import { isYouTubeUrl, fetchVideoDetails } from "@/helpers/youtubeScraper";
import thumbnailImg from "@/assets/VVV/images/sample-yt-thumbnail.png";
import { useTitle, useEventListener } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useUserDetailStore } from "@/store/userDetailStore";
import { useYoutubeStore } from "@/store/youtubeStore";
import VueFeather from 'vue-feather';

const router = useRouter();
const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);
const youtubeStore = useYoutubeStore();
const { youtubeVideo, ifHasUnsavedChanges, clipList, isAdded, youtubeVideoDuration } = storeToRefs(youtubeStore);
const isLoading = ref(true);

onMounted(() => {
    watch(userDetail, (newUserDetail) => {
        isLoading.value = false;
        useTitle("Loading | Very Viral Videos");
        if (newUserDetail.id === 1 || newUserDetail.id === 4) {
            router.push("/claims-list");
            return;
        }
        useTitle("New Claim | Very Viral Videos");
    });
});

const stepCounter = ref(1);
const stepper1Class = ref('stepper-step stepper-active');
const stepper2Class = ref('stepper-step');

watch(stepCounter, (step) => {
    if (step === 1) {
        stepper1Class.value = 'stepper-step stepper-active';
        stepper2Class.value = 'stepper-step';
    } else {
        stepper1Class.value = 'stepper-step';
        stepper2Class.value = 'stepper-step stepper-active';
    }
});

const errorToast = ref(false);
const errorToastMessage = ref("Error");



const validateStepOne = async () => {
    if (youtubeVideo.value.url != '') {
        if (!isYouTubeUrl(youtubeVideo.value.url)) {
            errorToastMessage.value = "Enter a valid YouTube URL.";
            errorToast.value = true;
        } else {
            try {
                const videoDetails = await fetchVideoDetails(youtubeVideo.value.url);
                youtubeVideoDuration.value = videoDetails.duration;
                stepCounter.value++;
            } catch (error) {
                errorToastMessage.value = "Failed to fetch video details.";
                errorToast.value = true;
            }
        }
    } else {
        errorToastMessage.value = "Enter a YouTube URL to proceed to the next step.";
        errorToast.value = true;
    }
};



const minuteSecondRegex = /^([0-5][0-9]):([0-5][0-9])$/; // mm:ss
const hourMinuteSecondRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/; // hh:mm:ss

const isValidTime = (videoDuration, inputTime) => {
    const hasHourComponent = videoDuration.split(":").length === 3;
    return hasHourComponent ? hourMinuteSecondRegex.test(inputTime) : minuteSecondRegex.test(inputTime);
};


const addNewClip = (clipToCopy = null, index = null) => {
    const newClip = clipToCopy
        ? JSON.parse(JSON.stringify(clipToCopy))
        : {
            startTime: '',
            endTime: '',
            clipURL: '',
            clipThumbnail: thumbnailImg,
            description: '',
            class: {
                clipTitle: 'form-control',
                startTime: 'form-control',
                endTime: 'form-control',
                clipURL: 'form-control'
            },
            errorMessage: {
                clipTitle: { class: 'd-none', displayText: '' },
                startTime: { class: 'd-none', displayText: '' },
                endTime: { class: 'd-none', displayText: '' },
                clipURL: { class: 'd-none', displayText: '' }
            }
        };

    if (index !== null) {
        clipList.value = [
            ...clipList.value.slice(0, index + 1),
            newClip,
            ...clipList.value.slice(index + 1)
        ];
    } else {
        clipList.value = [...clipList.value, newClip];
    }
};

const removeClip = (index) => {
    clipList.value = clipList.value.filter((_, i) => i !== index);
};

const duplicateClip = (index) => {
    const clipToCopy = clipList.value[index];
    addNewClip(clipToCopy, index);
};

const isValidUrl = (url) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}(\/.+)?$/;
    return urlPattern.test(url);
};

const loading = ref(false);
const submitNewClaim = async () => {
    const hasError = ref(false);

    clipList.value.forEach((clip, index) => {
        clip.class.clipTitle = clip.clipTitle ? 'form-control' : 'form-control border border-danger border-3';
        clip.errorMessage.clipTitle.class = clip.clipTitle ? 'd-none' : 'd-block text-danger';
        clip.errorMessage.clipTitle.displayText = clip.clipTitle ? '' : 'Empty clip title';
        const youtubeDuration = youtubeVideoDuration.value;

        if (isValidTime(youtubeDuration, clip.startTime) && isValidTime(youtubeDuration, clip.endTime)) {
            const startTimeMinutes = youtubeStore.convertToMinutes(clip.startTime);
            const endTimeMinutes = youtubeStore.convertToMinutes(clip.endTime);

            if (startTimeMinutes < endTimeMinutes) {
                clip.class.startTime = clip.class.endTime = 'form-control';
                clip.errorMessage.startTime.class = clip.errorMessage.endTime.class = 'd-none';
                clip.errorMessage.startTime.displayText = clip.errorMessage.endTime.displayText = '';

                if (startTimeMinutes <= youtubeStore.convertToMinutes(youtubeDuration) || endTimeMinutes < youtubeStore.convertToMinutes(youtubeDuration)) {
                    clip.class.startTime = clip.class.endTime = 'form-control';
                    clip.errorMessage.startTime.class = clip.errorMessage.endTime.class = 'd-none';
                    clip.errorMessage.startTime.displayText = clip.errorMessage.endTime.displayText = '';
                } else {
                    clip.class.startTime = clip.class.endTime = 'form-control border border-danger border-3';
                    clip.errorMessage.startTime.class = clip.errorMessage.endTime.class = 'd-block text-danger';
                    clip.errorMessage.startTime.displayText = clip.errorMessage.endTime.displayText = 'Invalid clip duration';
                    errorToastMessage.value = `Clip ${index + 1}: Clip duration must not exceed the youtube video duration.`;
                    errorToast.value = true;
                    hasError.value = true;
                }
            } else {
                clip.class.startTime = clip.class.endTime = 'form-control border border-danger border-3';
                clip.errorMessage.startTime.class = clip.errorMessage.endTime.class = 'd-block text-danger';
                clip.errorMessage.startTime.displayText = clip.errorMessage.endTime.displayText = 'Invalid time sequence';
                errorToastMessage.value = `Clip ${index + 1}: Start time must be before end time.`;
                errorToast.value = true;
                hasError.value = true;
            }
        } else {
            clip.class.startTime = isValidTime(youtubeDuration, clip.startTime) ? 'form-control' : 'form-control border border-danger border-3';
            clip.errorMessage.startTime.class = isValidTime(youtubeDuration, clip.startTime) ? 'd-none' : 'd-block text-danger';
            clip.errorMessage.startTime.displayText = isValidTime(youtubeDuration, clip.startTime) ? '' : 'Invalid time format';
            clip.class.endTime = isValidTime(youtubeDuration, clip.endTime) ? 'form-control' : 'form-control border border-danger border-3';
            clip.errorMessage.endTime.class = isValidTime(youtubeDuration, clip.endTime) ? 'd-none' : 'd-block text-danger';
            clip.errorMessage.endTime.displayText = isValidTime(youtubeDuration, clip.endTime) ? '' : 'Invalid time format';
            if (!isValidTime(youtubeDuration, clip.startTime) || !isValidTime(youtubeDuration, clip.endTime)) {
                errorToastMessage.value = `Clip ${index + 1}: Enter a valid time in ${youtubeStore.hasHour(youtubeDuration) ? 'HH:MM:SS' : 'MM:SS'} format.`;
                errorToast.value = true;
                hasError.value = true;
            }
        }

        clip.class.clipURL = isValidUrl(clip.clipURL) ? 'form-control' : 'form-control border border-danger border-3';
        clip.errorMessage.clipURL.class = clip.clipURL !== null ? 'd-none' : 'd-block text-danger';
        clip.errorMessage.clipURL.displayText = clip.clipURL !== null ? '' : 'Enter a Clip URL';
        if (!isValidUrl(clip.clipURL)) {
            clip.errorMessage.clipURL.class = 'd-block text-danger';
            clip.errorMessage.clipURL.displayText = 'Invalid Clip URL';
            errorToastMessage.value = `Clip ${index + 1}: Enter a valid Clip URL.`;
            errorToast.value = true;
            hasError.value = true;
        }

        if (!clip.clipTitle || !clip.clipURL || !clip.startTime || !clip.endTime) {
            errorToastMessage.value = `Clip ${index + 1}: All fields are required.`;
            errorToast.value = true;
            hasError.value = true;
        }
    });

    if (!hasError.value) {
        loading.value = true;
        const submitResponse = await youtubeStore.submitVideo();
        if (submitResponse) {
            isAdded.value = true;
            youtubeStore.resetYoutubeVideo();
            youtubeStore.resetYoutubeChannel();
            youtubeStore.resetClipList();
            loading.value = false;
            router.push("/claims-list");
        } else {
            errorToastMessage.value = "Failed to submit!";
            errorToast.value = true;
            loading.value = false;
        }
    }
};

useEventListener(window, "beforeunload", () => {
    youtubeStore.resetYoutubeVideo();
    youtubeStore.resetYoutubeChannel();
    youtubeStore.resetClipList();
});

onBeforeRouteLeave((_to, _from, next) => {
    if (!ifHasUnsavedChanges.value) {
        next();
    } else {
        const answer = window.confirm('Do you really want to leave? You have unsaved changes!');
        if (answer) {
            youtubeStore.resetYoutubeVideo();
            youtubeStore.resetYoutubeChannel();
            youtubeStore.resetClipList();
            next();
        } else {
            next(false);
        }
    }
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.stepper-container {
    width: 40%;
}

@media screen and (max-width: 767px) {
    .stepper-container {
        width: 100%;
    }
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
