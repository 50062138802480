import { HttpClient } from "../HttpClient";
/**
 * * Get Youtube Channel Information List
 */
export const GetYoutubeChannelInformation = async (userId) => {
  try {
    const { data } = await HttpClient.get(
      `api/YoutubeChannelInformation/list`,
      {
        params: {
          UserId: userId,
          rowsPerPage: 100,
        },
      }
    );
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
