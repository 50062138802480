<template>
    <MDBCard class="shadow mt-3">
        <MDBCardHeader>
            <h4>Create a new video claim</h4>
            <p class="mb-0">Submit a claim here if you've created a video containing our clips. Our team will review and take action
                on your behalf.</p>
        </MDBCardHeader>
        <MDBCardBody>
            <h4>Claiming Guidelines</h4>
            <div class="alert-container">
                <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                    style="color: var(--primary)" />
                <p class="mb-0 alert-message">
                    Make sure the clips you use from us are central to most of the video.
                </p>
            </div>
            <div class="alert-container">
                <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                    style="color: var(--primary)" />
                <p class="mb-0 alert-message">
                    Do not embed clips in full TV/movie episodes or heavily copyright material
                </p>
            </div>
            <div class="alert-container">
                <vue-feather type="check-circle" size="14" :stroke-width="2.5" class="me-2"
                    style="color: var(--primary)" />
                <p class="mb-0 alert-message">
                    We can only claim our licensed footage, so use accurate and timestamps
                </p>
            </div>
        </MDBCardBody>
    </MDBCard>
    <MDBAlert color="info" static class="mt-3 px-4 py-3 mb-4"
        style="font-size: 16px;display: flex;gap: 10px;align-items: center;">
        <div class="alert-message">
            Select the YouTube channel this video is published on, and ensure your video is <span
                class="text-success">public</span>.
        </div>
    </MDBAlert>
    <div class="d-flex justify-content-center flex-column">
        <h4 class="mb-3 fw-bold" style="font-size: 18px;">YouTube Channel List</h4>
        <div id="youtubeChannelList" class="mb-4 px-3"
            style="width: max-content; max-width: 100%; max-height: 30vh; overflow: auto;">
            <div class="image-container image-container-big mx-auto" v-if="youtubeListLoading">
                <div class="loader"></div>
                <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
            </div>
            <template v-else>
                <div v-for="channel in youtubeChannelList" :key="channel.id"
                    class="d-flex justify-content-between align-items-center py-2">
                    <div class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
                        <MDBRadio :id="channel.channelId" name="youtube-channel" v-model="selectedYoutubeChannelId"
                            :value="channel.channelId" />
                        <img :src="channel.thumbnail" alt="thumbnail" style="width: 50px; height: 50px"
                            class="rounded-circle" />
                        <div>
                            <p class="my-auto text-left fw-bold" style="font-size: 14px;">{{ channel.channelName
                                }}
                            </p>
                            <p class="my-auto text-left" style="font-size: 13px;">{{ channel.subscriberCount }}
                                subscribers</p>
                        </div>
                    </div>
                    <vue-feather type="trash" :stroke-width="2.5" size="16" class="py-1 px-0 ms-5"
                        style="cursor: pointer;" @click="toggleDeleteModal(channel.channelId)" title="Delete channel" />
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
                        <MDBRadio id="GetFromVideo" name="youtube-channel" v-model="selectedYoutubeChannelId"
                            value="GetFromVideo" />
                        <img src="@/assets/user-default.png" alt="thumbnail" style="width: 50px; height: 50px"
                            class="rounded-circle" />
                        <label for="GetFromVideo" style="cursor: pointer;">Get from Video</label>
                    </div>
                </div>
            </template>
        </div>
        <h4 class="fw-bold" style="font-size: 18px;">YouTube Video</h4>
        <div class="input-group w-100 mt-2">
            <MDBBtn color="primary" size="sm">
                <i class="fab fa-youtube fa-fw pb-1"></i>
            </MDBBtn>
            <input v-model="inputVideoUrl" @input="updateYTUrlValue" type="text" class="form-control"
                placeholder="YouTube URL" @keyup.enter="submit" />
            <MDBBtn class="btn btn-primary fw-bold" type="button" @click="submit">
                Preview
            </MDBBtn>
        </div>
    </div>
    <template v-if="youtubeVideo.title != ''">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
            </div>
        </div>
        <div class="d-flex flex-column justify-content-center overflow-auto" v-else>
            <h5 class="text-center w-100 mt-4 fw-bold">Preview:</h5>
            <img :src="youtubeVideo.thumbnail" class="w-50 d-flex mx-auto mt-3" alt="YouTube Thumbnail" />
            <div class="d-flex gap-2 mt-4 justify-content-center">
                <div
                    style="width: 63px; height: 63px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center;">
                    <img :src="youtubeVideo.channelThumbnail" alt="YT Video Thumbnail"
                        style="width: auto; height: 100%;" />
                </div>
                <div class="my-auto video-details">
                    <p class="my-auto fw-bold text-left">{{ youtubeVideo.title }}</p>
                    <p class="my-auto text-left fw-bold" style="font-size: 13px;">{{ youtubeVideo.channelName }}</p>
                    <p class="my-auto text-left" style="font-size: 13px;">{{ youtubeVideo.viewCount }} subscribers</p>
                </div>
            </div>
        </div>
    </template>
    <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal">
        <MDBModalHeader>
            <MDBModalTitle class="fw-bold" id="deleteModalLabel"> Delete Channel </MDBModalTitle>
        </MDBModalHeader>
        <MDBModalBody>Are you sure you want to delete this channel?</MDBModalBody>
        <MDBModalFooter class="gap-2">
            <MDBBtn class="btn fw-bold" size="sm" @click="deleteModal = false">Close</MDBBtn>
            <MDBBtn class="btn btn-danger fw-bold" size="sm" @click="confirmDeletion()">Confirm Delete
            </MDBBtn>
        </MDBModalFooter>
    </MDBModal>
    <MDBToast v-model="errorToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
        color="danger" text="white" icon="fas fa-check fa-lg me-2">
        <template #title> Error </template>
        {{ errorToastMessage }}
    </MDBToast>
</template>

<script setup>
import { MDBBtn, MDBToast, MDBAlert, MDBRadio, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBCard, MDBCardHeader, MDBCardBody } from "mdb-vue-ui-kit";
import { ref, defineEmits, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { isYouTubeUrl, fetchChannelDetailsBatch } from "@/helpers/youtubeScraper";
import { useYoutubeStore } from "@/store/youtubeStore";
import { useUserDetailStore } from "@/store/userDetailStore";
import { GetYoutubeChannelInformation } from "@/services/YoutubeChannelInformation/GetYoutubeChannelInformation";
import { DeleteYoutubeChannelInformation } from "@/services/YoutubeChannelInformation/DeleteYoutubeChannelInformation";
import VueFeather from 'vue-feather';

const youtubeStore = useYoutubeStore();
const { youtubeVideo, youtubeChannelList, selectedYoutubeChannelId } = storeToRefs(youtubeStore);

const emit = defineEmits(['update:youtubeURL']);
const inputVideoUrl = ref(youtubeVideo.value.url);

const updateYTUrlValue = () => {
    emit('update:youtubeURL', inputVideoUrl.value);
};

const loading = ref(false);
const errorToast = ref(false);
const errorToastMessage = ref("Error");

const submit = async () => {
    if (inputVideoUrl.value === "") {
        errorToastMessage.value = "Please enter a YouTube URL to preview.";
        errorToast.value = true;
        return;
    }
    if (!isYouTubeUrl(inputVideoUrl.value)) {
        errorToastMessage.value = "Please enter a valid YouTube URL.";
        errorToast.value = true;
        return;
    }
    loading.value = true;
    // https://www.youtube.com/watch?v=KkCXLABwHP0
    // https://www.youtube.com/watch?v=eRzljx9nDdY
    youtubeStore.setYoutubeVideo(inputVideoUrl.value)
    loading.value = false;
};

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);

const youtubeListLoading = ref(true);

const getYoutubeChannelInformationList = async (userId) => {
    try {
        youtubeListLoading.value = true;
        const response = await GetYoutubeChannelInformation(userId);
        const channels = response.data;

        if (response.data.length > 0) {
            const channelIds = channels.map((channel) => channel.channelId);

            const detailedChannels = await fetchChannelDetailsBatch(channelIds);

            youtubeChannelList.value = channels.map((channel) => {
                const details = detailedChannels.find(detail => detail.channelId === channel.channelId);
                return {
                    ...channel,
                    ...details,
                };
            });

            selectedYoutubeChannelId.value = response.data[0].channelId;
        } else {
            youtubeChannelList.value = [];
            selectedYoutubeChannelId.value = 'GetFromVideo';
        }
        youtubeListLoading.value = false;
    } catch (error) {
        console.error("Error fetching YouTube channel information:", error);
    }
};

const deleteModal = ref(false);
const channel = ref("");

const toggleDeleteModal = (channelId) => {
    deleteModal.value = true;
    channel.value = channelId;
    console.log('toggleDeleteModal', channelId);
};

const confirmDeletion = () => {
    deleteChannel(channel.value);
};

const deleteChannel = async (channelId) => {
    await DeleteYoutubeChannelInformation(channelId);
    getYoutubeChannelInformationList(userDetail.value.id);
    deleteModal.value = false;
    console.log(channelId);
};

onMounted(() => {
    getYoutubeChannelInformationList(userDetail.value.id);
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.video-details {
    width: 40%;
}

.alert-container {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
}

.alert-message {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .video-details {
        width: 60%;
    }

    .alert-message {
        width: 90%;
    }
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#youtubeChannelList::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

#youtubeChannelList::-webkit-scrollbar-thumb {
    background-color: #888;
}

#youtubeChannelList::-webkit-scrollbar-track {
    background-color: #eee;
}

.card-header h4,
.card-body h4 {
    color: black !important;
    font-family: 'GilroyBold', sans-serif;
    font-size: 16px;
}
</style>
